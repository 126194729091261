import {FormControlLabel, Radio, styled, Typography} from '@mui/material';
import React, {memo, useMemo} from 'react';

const RadioChoice = styled('div')(({theme, checked}) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    gap: 1.5,
    width: '100%',
    padding: '12px 16px',
    borderWidth: checked ? 2 : 1,
    borderStyle: 'solid',
    borderColor: checked ? theme.palette.primary.main : theme.palette.border.main,
    borderRadius: 4,
    minHeight: 104,
}));

const RadioChoices = ({items, current_value}) => {
    return useMemo(() => {
        return items.map(({label, value, description, addon}) => (
            <FormControlLabel
                key={label}
                value={value}
                required
                control={<Radio className="usable-hidden" />}
                label={
                    <RadioChoice checked={current_value + '' === value}>
                        <Typography variant="body2" component="div">
                            {label}
                        </Typography>
                        {description && (
                            <Typography
                                variant="caption"
                                component="div"
                                sx={(theme) => ({color: theme.palette.text.light})}
                            >
                                {description}
                            </Typography>
                        )}
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontWeight: 600,
                                fontStyle: 'italic',
                                textAlign: 'right',
                            }}
                        >
                            {addon}
                        </Typography>
                    </RadioChoice>
                }
                sx={{
                    flexFlow: 'column nowrap',
                    alignItems: 'stretch',
                    margin: 0,
                }}
            />
        ));
    }, [items, current_value]);
};

export default memo(RadioChoices);
